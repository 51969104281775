.marquee {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 35s linear infinite;
  }
  
  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  