/* CalendarMeals.css */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .calendar-container {
    height: calc(100vh - 200px); /* Adjust height according to your header and footer size */
    display: flex;
    flex-direction: column;
  }
  
  .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .fc {
    flex: 1;
  }
  
  .fc .fc-daygrid-day-number {
    padding: 4px;
    font-size: 14px;
    position: relative;
    z-index: 4;
    text-decoration: none;
}

  .fc-h-event .fc-event-main{
    color: blue;
  }

  .fc-h-event .fc-event-title {
    font-size: 12px;
  }
  .fc-h-event {
    padding-left: 5%;
    background-color: transparent;
    border: 1px solid transparent;
    display: block;
}